import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '@components/layout'
import SEO from '@components/seo'
import Header from '@components/header'
import { ParagraphBlock, ImageBlock, ImageContentBlock, ContentImageBlock, HeroBlock, FruitGridBlock, PartnersBlock } from '../components/page-blocks/blocks'

const PageTemplate = ({ data: { page: { data, lang, prismicId } }, pageContext: { alternateLanguages } }) => {
  const { blocks, seo, ...header } = data
  return (
    <Layout lang={lang} alternateLanguages={alternateLanguages}>
      <SEO title={header.header_title.text} />
      <Header title={header.header_title.text} text={header.header_subtitle.text} image={header.header_background_image} video={header.header_background_video} />
      {blocks.map(({ id, slice_type: type, primary: block, items }) => (
        <div key={id}>
          {type === 'paragraph_block' && <ParagraphBlock content={block.content} justification={block.content_justification} backgroundColor={block.content_background} />}
          {type === 'image_block' && <ImageBlock image={block.image} alt={block.image.alt} />}
          {type === 'image___content_block' && <ImageContentBlock content={block.content} backgroundColor={block.content_background} image={block.image} alt={block.image.alt} />}
          {type === 'content___image_block' && <ContentImageBlock content={block.content} backgroundColor={block.content_background} image={block.image} alt={block.image.alt} />}
          {type === 'hero_block' && <HeroBlock content={block.content} size={block.size} backgroundColor={block.content_background} image={block.background_image} alt={block.background_image.alt} />}
          {type === 'grid_content_block' && <FruitGridBlock items={items} />}
          {type === 'partners' && <PartnersBlock items={items} /> }
        </div>
      ))}
    </Layout>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object
}

export default PageTemplate

export const query = graphql`query PageTemplate ($prismicId: String!) {
    page: prismicPage (prismicId: {eq: $prismicId}) {
      id
      prismicId
      lang
      data {
        ...PageSEO
        ...PageHeader
        blocks: body {
          ...ParagraphBlock
          ...ImageBlock
          ...ImageContentBlock
          ...ContentImageBlock
          ...FruitGridBlock
          ...HeroBlock
          ...PartnersBlock
        }
      }
    }
  }
`
