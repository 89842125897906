import React from 'react'
import PropTypes from 'prop-types'
import Image from 'gatsby-image'
import { RichText } from 'prismic-reactjs'
import parseHtml from 'html-react-parser'

import { customLink } from '@components/link'
import BackgroundImage from 'gatsby-background-image'

export const ParagraphBlock = ({ content, justification, backgroundColor = 'white' }) => (
  <div className={`section block has-background-${backgroundColor}`}>
    <div className="container">
      <div className={`content block-content has-text-${justification}`}>
        <RichText render={content.raw} serializeHyperlink={customLink} />
      </div>
    </div>
  </div>
)
ParagraphBlock.propTypes = {
  content: PropTypes.object.isRequired,
  justification: PropTypes.string,
  backgroundColor: PropTypes.string
}

export const ImageBlock = ({ image, alt }) => (
  <div className="container-fluid block">
    <figure className="image block-image">
      <Image fluid={image.localFile.childImageSharp.fluid} alt={alt} />
    </figure>
  </div>
)
ImageBlock.propTypes = {
  image: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired
}

export const ImageContentBlock = ({ content, backgroundColor = 'white', image, alt }) => (
  <div className={`columns block has-background-${backgroundColor}`}>
    <div className="column is-half">
      <figure className="image block-column-image">
        <Image fluid={image.localFile.childImageSharp.fluid} alt={alt} />
      </figure>
    </div>
    <div className="column is-half">
      <div className="content block-content block-column-content">
        {parseHtml(content.html)}
      </div>
    </div>
  </div>
)
ImageContentBlock.propTypes = {
  content: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string
}
export const ContentImageBlock = ({ content, backgroundColor = 'white', image, alt }) => (
  <div className={`columns block has-background-${backgroundColor} is-paddingless`}>
    <div className="column is-half">
      <div className="content block-content block-column-content">
        {content && parseHtml(content.html)}
      </div>
    </div>
    <div className="column is-half">
      <figure className="image block-column-image">
        <Image fluid={image.localFile.childImageSharp.fluid} alt={alt} />
      </figure>
    </div>
  </div>
)
ContentImageBlock.propTypes = {
  content: PropTypes.object.isRequired,
  backgroundColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string
}

export const HeroBlock = ({ title, content, size = 'regular', backgroundColor = 'white', image, alt }) => (
  <BackgroundImage fluid={image.localFile.childImageSharp.fluid} alt={alt} className={`hero block is-${backgroundColor} is-${size.toLowerCase()}`}>
    <div className="hero-body">
      <div className="container">
        <h3 className="subtitle">{title && title.text}</h3>
        <div className="content">
          {content && parseHtml(content.html)}
        </div>
      </div>
    </div>
  </BackgroundImage>
)
HeroBlock.propTypes = {
  title: PropTypes.object,
  content: PropTypes.object,
  size: PropTypes.string,
  backgroundColor: PropTypes.string,
  image: PropTypes.object.isRequired,
  alt: PropTypes.string
}

export const FruitGridBlock = ({ items }) => (
  <div className="columns is-multiline block block-fruit-grid">
    {items.map(({ title, content, background_image }, i) => (
      <div key={i} className="column is-6">
        <BackgroundImage key={i} fluid={background_image.localFile.childImageSharp.fluid} alt={background_image.alt} className="hero is-medium">
          <div className="hero-body">
            <div className="container has-text-centered has-text-white has-text-weight-light">
              <h3 className="subtitle is-size-4 is-uppercase has-text-white has-text-weight-medium">{title && title.text}</h3>
              <div className="content">
                {content && parseHtml(content.html)}
              </div>
            </div>
          </div>
        </BackgroundImage>
      </div>
    ))}
  </div>
)
FruitGridBlock.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
}

export const PartnersBlock = ({ title = 'PARTNERS', items }) => (
  <div className={`section block has-background-primary`}>
    <div className="content block-partners has-text-centered">
      <h3 className="subtitle has-text-weight-light">{title}</h3>
      <br/>
      <div className="logos">
        {items.map((item, i) => (
          <a key={i} className="logo" href={item.partner_link.url} target="_blank" rel="noopener noreferrer">
            <Image fixed={item.partner_logo.localFile.childImageSharp.fixed} alt={item.partner_logo.alt} />
          </a>
        ))}
      </div>
    </div>
  </div>
)
PartnersBlock.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired
}
